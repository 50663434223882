import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'containerState',
  pure: false
})
export class ContainerStatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number): any {

    if(value <= 2) {
      // 0,1,2 = geplant
      return this.translateService.instant("pipes.container-state.planned");
    } else if (value === 3) {
      // 3 = gestaut
      return this.translateService.instant("pipes.container-state.stowed");
    }

    return value;

  }

}
